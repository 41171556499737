@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700,800|Roboto:400,500,700&display=swap");
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden !important; }

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box; }
  *::after, *::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    outline: 0; }
  *:active, *:focus, *:hover, *:visited {
    outline: 0;
    -webkit-tap-highlight-color: transparent; }

/* Webkit autofill background fix */
html body input:-webkit-autofill, html body input:-webkit-autofill:hover, html body input:-webkit-autofill:focus, html body input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  transition: background-color 500000s ease-in-out 0s !important; }

button,
input,
hr,
optgroup,
select,
textarea,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0; }

progress,
sub,
sup {
  vertical-align: baseline; }

pre,
code {
  overflow: auto; }

embed,
iframe,
img,
legend,
object,
pre,
details {
  max-width: 100%;
  display: block;
  border: 0;
  outline: 0; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

a {
  background-color: transparent;
  text-decoration: unset;
  color: unset; }
  a:active, a:focus, a:hover, a:visited {
    color: unset;
    text-decoration: unset; }

b,
strong {
  font-weight: bold; }

code,
kbd,
samp,
pre {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border: none;
  height: auto; }

button,
select,
input,
textarea,
label {
  text-transform: unset;
  border: none;
  outline: none;
  background: unset; }

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button; }

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: ButtonText dotted 1px; }

textarea {
  width: 100%;
  display: block;
  resize: vertical; }

[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

.hide,
.hidden,
[hidden] {
  display: none; }

body,
button,
input,
optgroup,
select,
textarea {
  color: #404040;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.5; }

cite,
dfn,
em,
i {
  font-style: italic; }

blockquote {
  margin: 0 1.5em; }

address {
  margin: 0 0 1.5em; }

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  padding: 1.6em;
  color: #000; }

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 0.9375rem; }

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help; }

ins,
mark {
  background: #fff9c0;
  text-decoration: none; }

big {
  font-size: 125%; }

ol,
ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

ol {
  list-style-type: decimal; }

li > ol,
li > ul {
  margin: 0;
  padding: 0; }

dt {
  font-weight: 700; }

dd {
  margin: 0 1.5em 1.5em; }

figure {
  margin: 0; }

table {
  margin: 0;
  width: auto; }

input[type="color"]:focus,
input[type="date"]:focus,
input[type="datetime-local"]:focus,
input[type="datetime"]:focus,
input[type="email"]:focus,
input[type="month"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="range"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="week"]:focus,
textarea:focus {
  color: inherit; }

a:active,
a:focus,
a:hover {
  color: inherit; }

input {
  appearance: none; }
  input[type="search"] {
    appearance: textfield;
    outline: none; }
  input::-webkit-search-cancel-button {
    display: none;
    pointer-events: none;
    opacity: 0;
    visibility: hidden; }

html {
  font-size: 16px; }

@media (min-width: 1600px) {
  .container {
    max-width: 1515px; } }

body {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  font-size: 14px;
  color: #fff;
  background-color: #000; }

.mon {
  font-family: 'Montserrat', sans-serif; }

a:hover {
  cursor: pointer; }

button {
  cursor: pointer;
  outline: none !important; }

main {
  padding-top: 120px; }

textarea {
  resize: none; }

button {
  font-size: unset; }

.just_center {
  justify-content: center;
  display: flex; }

.just_btwn {
  justify-content: space-between;
  display: flex; }

.align_center {
  display: flex;
  align-items: center; }

header {
  height: 120px;
  padding: 0 35px;
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: #000; }
  header .container-fluid {
    height: 100%; }
    header .container-fluid > div:last-child {
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.3em; }

.header_logo {
  margin-left: 90px; }

.header_lang {
  margin-right: 15vw;
  color: #828282; }
  .header_lang span:first-child {
    margin-right: 50px;
    color: #fff; }

.header_tel span {
  margin-left: 20px; }

.menu_btn_wrap p {
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase; }

.ornage_btn {
  font-family: 'Montserrat', sans-serif;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  width: 550px;
  background: #EFC94F;
  transition: all 0.3s; }
  .ornage_btn span {
    font-size: 14px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000; }
  .ornage_btn:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); }

.first_view {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  padding-top: 85px;
  padding-bottom: 120px;
  background-image: url("../img/fbg.png");
  background-size: cover; }
  .first_view .ornage_btn {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); }
    .first_view .ornage_btn:hover {
      box-shadow: none; }
  .first_view:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.6; }
  .first_view_content {
    position: relative;
    z-index: 1; }
    .first_view_content p {
      font-weight: bold;
      font-size: 56px;
      line-height: 70px;
      text-transform: uppercase;
      max-width: 700px;
      margin-bottom: 20px; }
    .first_view_content_bottom {
      display: flex;
      align-items: flex-end; }
  .first_view ul {
    margin-right: 120px; }
    .first_view ul li {
      position: relative;
      padding-left: 30px;
      text-transform: capitalize;
      font-size: 24px;
      line-height: 46px; }
      .first_view ul li::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: #F5CB45;
        transform: rotate(45deg) translate(0, -50%);
        top: 50%;
        left: 0; }

.section_title {
  position: relative;
  text-transform: uppercase;
  height: 160px;
  display: flex;
  align-items: flex-end; }
  .section_title_bg {
    font-weight: bold;
    font-size: 144px;
    opacity: 0.1;
    line-height: 105%;
    position: absolute;
    left: -100px;
    bottom: 0; }
  .section_title_sm {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px; }
    .section_title_sm:after {
      content: '';
      position: absolute;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 130px;
      height: 2px;
      background-color: #F5CB45; }

.item_link {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 16px;
  max-height: 370px; }
  .item_link img {
    width: 100%; }
  .item_link_dsc {
    position: absolute;
    bottom: -300px;
    left: 0;
    width: 100%;
    padding: 30px  10%;
    background: rgba(19, 27, 34, 0.7);
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    transition: all 0.4s; }
    .item_link_dsc p {
      font-size: 20px;
      line-height: 120%;
      margin-bottom: 20px; }
    .item_link_dsc div {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      line-height: 120%; }

.item_link:hover .item_link_dsc {
  bottom: 0px; }

.transparent_btn {
  font-family: 'Montserrat', sans-serif;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  border: 1px solid #fff;
  width: 590px; }
  .transparent_btn span {
    font-size: 14px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000; }
  .transparent_btn span {
    margin-right: 30px; }

.new_proj {
  padding-top: 50px;
  padding-bottom: 100px; }
  .new_proj .section_title {
    margin-bottom: 55px; }
  .new_proj .transparent_btn {
    margin-top: 75px; }
    .new_proj .transparent_btn span {
      color: #fff; }

.services_item {
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.2);
  width: 282px;
  height: 282px;
  margin-top: 80px; }
  .services_item p {
    margin-top: 40px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px; }

.services {
  background-color: #EFC94F;
  position: relative; }
  .services > * {
    position: relative;
    z-index: 2; }
  .services::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 360px;
    background-color: #000;
    z-index: 1; }
  .services:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 550px;
    background-color: #fff;
    z-index: 1; }
  .services_row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; }
    .services_row > div {
      width: 33%;
      display: flex;
      justify-content: center; }
  .services .transparent_btn {
    margin-top: 70px;
    border: 1px solid #000;
    margin-bottom: 100px; }
  .services_title {
    background-color: #000; }

.calc {
  background: #333333;
  width: 768px;
  padding: 75px 155px;
  position: relative;
  z-index: 2; }
  .calc_sub_title {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.3em;
    margin-top: 15px;
    margin-bottom: 36px; }
  .calc_title {
    font-weight: bold;
    font-size: 56px;
    line-height: 120%;
    text-transform: uppercase; }

.calc_steps {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  margin-bottom: 70px; }
  .calc_steps > div {
    position: relative; }
    .calc_steps > div span {
      display: flex;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 20px; }
    .calc_steps > div::after, .calc_steps > div:before {
      content: '';
      position: absolute;
      top: 50%;
      height: 1px;
      width: 100px;
      background-color: #EFC94F; }
    .calc_steps > div:after {
      left: 50px; }
    .calc_steps > div:before {
      right: 50px; }

.calc_step_1 {
  margin-left: calc(33% - 35px); }
  .calc_step_1 span {
    background-color: #EFC94F;
    color: #000000; }

.calc_step_2 {
  margin-right: calc(33% - 35px); }
  .calc_step_2 span {
    background-color: transparent;
    border: 1px solid #fff; }

.calc .jqselect, .filter_select .jqselect {
  width: 100%;
  margin-bottom: 45px; }

.calc .jq-selectbox__select, .filter_select .jq-selectbox__select {
  background: #333;
  border: 1px solid #fff !important;
  border-radius: 0;
  color: #fff;
  height: 50px;
  outline: none; }
  .calc .jq-selectbox__select-text, .filter_select .jq-selectbox__select-text {
    text-shadow: none;
    height: 100%;
    line-height: 50px; }

.calc .jq-selectbox__trigger, .filter_select .jq-selectbox__trigger {
  border: none;
  width: 50px; }

.calc .jq-selectbox__trigger-arrow, .filter_select .jq-selectbox__trigger-arrow {
  border-top: 5px solid #fff !important;
  top: 50%;
  transform: translate(50%, -50%);
  right: 50%; }

.calc .jq-selectbox__dropdown, .filter_select .jq-selectbox__dropdown {
  padding: 5px 30px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 0;
  border: none;
  top: 53px; }
  .calc .jq-selectbox__dropdown::after, .filter_select .jq-selectbox__dropdown::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    top: -11px;
    right: 14px; }

.calc .ornage_btn, .filter_select .ornage_btn {
  box-shadow: none; }

.calc input, .filter_select input {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  font-size: 14px;
  margin-bottom: 45px; }

.jq-selectbox li {
  padding: 15px 5px;
  border-bottom: 1px solid #E0E0E0; }

.jq-selectbox li.selected {
  background-color: transparent;
  color: #000; }

.jq-selectbox li:hover {
  background-color: transparent;
  color: #000;
  font-weight: bold; }

.calc_1 .calc_step_2::after {
  background-color: #FFFFFF; }

.calc_2 .calc_step_2 span {
  background-color: #EFC94F;
  color: #333;
  border: none; }

.calc_1, .calc_2 {
  display: none; }

.active_calc {
  display: block; }

.calc_section {
  position: relative;
  padding: 50px 0; }
  .calc_section .container {
    display: flex;
    justify-content: flex-end; }
  .calc_section_img {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 68vw;
    height: 100%; }
    .calc_section_img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.done_proj {
  background-color: #fff;
  color: #000;
  padding-top: 250px; }
  .done_proj .transparent_btn {
    border: 1px solid #191919; }
    .done_proj .transparent_btn span {
      color: #000; }
  .done_proj .item_link_dsc {
    text-align: center;
    color: #fff; }

.home_news .news_item {
  max-width: 593px;
  margin-top: 26px; }

.home_news .col-lg-6 {
  display: flex;
  flex-direction: column; }

.home_news .col-lg-6:nth-child(2) {
  margin-top: -100px; }
  .home_news .col-lg-6:nth-child(2) > div:first-child {
    background-color: #fff; }

.home_news .col-lg-6:nth-child(1) {
  align-items: flex-end; }
  .home_news .col-lg-6:nth-child(1) > div:last-child {
    background-color: #fff; }

.news_item {
  padding: 50px 10%;
  color: #000;
  background-color: #EFC94F;
  line-height: 170%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); }
  .news_item_category {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    margin-left: -10px; }
  .news_item_title {
    font-weight: 500;
    font-size: 45px;
    line-height: 120%;
    margin: 30px 0; }
  .news_item .more_btn {
    margin-top: 40px; }

.more_btn {
  border: none;
  width: auto;
  height: auto;
  justify-content: flex-start; }
  .more_btn span {
    margin-right: 15px; }

.home_news {
  background-color: #fff; }
  .home_news .section_title {
    color: #000; }
  .home_news .news_content {
    position: relative;
    background-color: #333;
    background-image: url("../img/bg1.png");
    background-repeat: no-repeat;
    background-position-y: 170px; }
    .home_news .news_content > .container {
      position: relative;
      z-index: 2; }
    .home_news .news_content::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 170px;
      background-color: #fff;
      z-index: 0; }

.seo_block {
  margin-top: 180px;
  margin-bottom: 120px; }

.seo_title {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 30px; }

.seo_content {
  padding-right: 50px;
  max-height: 250px;
  overflow-y: scroll;
  font-size: 16px;
  line-height: 24px; }

.footer_contacts {
  background: #181818;
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.3em;
  text-align: center;
  color: #FFFFFF; }
  .footer_contacts_item {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 70px; }
    .footer_contacts_item:hover {
      background-color: #131313; }
    .footer_contacts_item > div {
      margin-bottom: 35px; }

.footer_main {
  display: flex;
  justify-content: space-between;
  padding: 0 8%;
  padding-top: 105px;
  padding-bottom: 80px; }

.footer_logo_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.directory {
  letter-spacing: 0.3em;
  font-family: 'Montserrat', sans-serif; }
  .directory > p {
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 35px; }
  .directory li {
    font-size: 16px;
    line-height: 20px;
    color: #C4C4C4; }
    .directory li:not(:last-child) {
      margin-bottom: 15px; }

.lang_and_link {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-size: 18px; }

.footer_lang, .menu_lang {
  color: #828282;
  margin-bottom: 55px; }
  .footer_lang span:first-child, .menu_lang span:first-child {
    color: #fff;
    margin-right: 30px; }

.side_nav {
  position: absolute;
  right: 40px;
  top: 130px;
  z-index: 20;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  white-space: nowrap;
  width: 10px; }
  .side_nav ul {
    display: flex;
    transform: rotate(90deg); }
  .side_nav li {
    margin-right: 25px;
    position: relative;
    transition: all 0.25s; }
    .side_nav li:not(:last-child):after {
      content: '';
      position: absolute;
      right: -21px;
      width: 18px;
      height: 1px;
      top: calc(50%);
      transform: translateY(-50%);
      background-color: #fff; }
    .side_nav li.active {
      color: #EFC94F; }

.sticky {
  position: fixed;
  top: 140px; }

.side_nav li.dark {
  color: #131313; }
  .side_nav li.dark.active {
    color: #EFC94F; }

.side_nav li.one_dark.active {
  color: #131313; }

.menu {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  display: none; }
  .menu_content {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    width: 100%; }
  .menu .close_btn {
    position: absolute;
    left: 50px;
    top: 50px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center; }
    .menu .close_btn span {
      letter-spacing: 0.5em;
      margin-left: 20px; }
  .menu li {
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;
    transform: translateY(-100vh);
    transition: all 0.8s; }
    .menu li:not(:last-child) {
      margin-bottom: 5vh; }
  .menu .transparent_btn {
    margin-top: 140px;
    border: 1px solid #F5CB45;
    width: 440px; }
    .menu .transparent_btn span {
      color: #F5CB45; }

.menu_lang {
  font-size: 24px;
  line-height: 29px; }

.menu_right > * {
  transform: translateX(100vw);
  transition: all 0.5s; }
  .menu_right > *:first-child {
    transition-delay: 1.2s; }
  .menu_right > *:last-child {
    transition-delay: 1s; }

.li_transform {
  transform: translateY(0) !important; }

.menu li:nth-child(7) {
  transition-delay: 0.45s; }

.menu li:nth-child(6) {
  transition-delay: 0.55s; }

.menu li:nth-child(5) {
  transition-delay: 0.65s; }

.menu li:nth-child(4) {
  transition-delay: 0.75s; }

.menu li:nth-child(3) {
  transition-delay: 0.85s; }

.menu li:nth-child(2) {
  transition-delay: 0.95s; }

.menu li:nth-child(1) {
  transition-delay: 1s; }

.calc .ornage_btn {
  background-color: #4F4F4F; }

.ornage_btn {
  transition: all 0.3s; }

.ornage_btn:hover {
  box-shadow: 0px 4px 10px rgba(239, 201, 79, 0.75); }

.transparent_btn:hover img {
  transform: scale(1.2) translateX(10px); }

.transparent_btn img, .menu_lang span, .footer_lang span, .header_lang span {
  transition: all 0.3s;
  cursor: pointer; }

.menu li a, .close_btn, .directory li, .lang_and_link > a, .header_tel span {
  transition: color 0.2s; }

.menu_lang span:hover, .footer_lang span:hover, .menu li:hover a, .close_btn:hover,
.header_lang span:hover, .directory li:hover, .header_tel:hover {
  cursor: pointer;
  color: #EFC94F; }

.close_btn {
  cursor: pointer; }

.lang_and_link > a:hover {
  color: #4267B2; }

.calc .jq-selectbox__dropdown::after {
  display: none; }

.menu {
  overflow: hidden; }

.seo_block {
  overflow: hidden; }

.calc_page {
  background-color: #000; }

.promo_page {
  background-color: #fff;
  color: #000;
  padding-bottom: 100px; }
  .promo_page .bread_crumbs {
    margin-bottom: 30px; }
  .promo_page .section_title_sm {
    margin-bottom: 40px; }
  .promo_page p {
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 160%; }
  .promo_page .promo_imgs {
    justify-content: center; }

.header_logo a img, .footer_logo_wrap a img {
  transition: all 0.3s; }
  .header_logo a img:hover, .footer_logo_wrap a img:hover {
    transform: scale(1.1); }

.interesting_block {
  transition: all 0.3s; }
  .interesting_block:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); }

.contacts_page_content a:hover {
  color: #EFC94F; }

.contacts_page_content form .ornage_btn {
  cursor: pointer; }
  .contacts_page_content form .ornage_btn:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); }

.ornage_btn {
  cursor: pointer; }

.lang_and_link > a {
  display: block; }

.lang_and_link > a:last-child {
  margin-top: 30px; }
  .lang_and_link > a:last-child:hover {
    color: #FE33B9; }

.directory_page .container-fluid {
  padding: 0 50px; }

.directory_page .news_item {
  padding: 50px 8%; }
  .directory_page .news_item_title {
    font-size: 30px;
    max-width: 260px;
    line-height: 54px; }
  .directory_page .news_item_category {
    font-size: 12px; }
  .directory_page .news_item .more_btn span {
    font-size: 12px; }
  .directory_page .news_item .more_btn img {
    width: 15px; }

.news_item_blue {
  background-color: #6F8997; }
  .news_item_blue * {
    color: #fff !important; }

.news_item_green {
  background-color: #6F9792; }
  .news_item_green * {
    color: #fff !important; }

.news_item_white {
  background-color: #fff; }

.items_row {
  width: 100%;
  display: grid;
  padding-top: 110px;
  padding-bottom: 40px;
  grid-gap: 20px;
  max-width: 100%;
  grid-template-columns: repeat(4, 1fr); }
  .items_row > div {
    position: relative;
    width: 100%;
    max-width: 100%; }
    .items_row > div:nth-of-type(8n+2) {
      transform: translateY(-70px); }
    .items_row > div:nth-of-type(8n+4) {
      transform: translateY(-70px); }
    .items_row > div:nth-of-type(8n+6) {
      transform: translateY(-70px); }
    .items_row > div:nth-of-type(8n+8) {
      transform: translateY(-70px); }

.show_more {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 580px;
  max-width: 100%;
  height: 50px;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  position: relative;
  box-shadow: inset 0 0 0 2px #fff; }
  .show_more:after {
    content: '';
    position: absolute;
    border: 2px solid transparent;
    width: 0;
    height: 0;
    top: 0px;
    left: 0px; }
  .show_more:before {
    content: '';
    position: absolute;
    border: 2px solid transparent;
    width: 0;
    height: 0;
    bottom: 0px;
    right: 0px; }
  .show_more:hover:before {
    width: 100%;
    height: 100%;
    border-top-color: #fbca67;
    border-right-color: #fbca67;
    transition: height 0.15s ease-out, width 0.25s ease-out 0.25s; }
  .show_more:hover:after {
    width: 100%;
    height: 100%;
    border-bottom-color: #fbca67;
    border-left-color: #fbca67;
    transition: height 0.15s ease-out, width 0.25s ease-out 0.25s; }
  .show_more svg {
    transition: all 0.8s ease-out; }
    .show_more svg path {
      transition: all 0.8s ease-out; }
  .show_more:hover svg {
    transform: rotate(360deg); }
    .show_more:hover svg path {
      fill: #fbca67; }

.pag {
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  justify-content: center;
  margin: 75px 0;
  align-items: center; }
  .pag img {
    transition: all 0.3s; }
  .pag li:not(:last-child) {
    margin-right: 20px; }
  .pag li:nth-child(6), .pag li:nth-child(7) {
    transform: scale(-1, 1); }
  .pag li:nth-child(5), .pag li:nth-child(2) {
    margin-right: 40px; }
  .pag li:hover {
    color: #fbca67; }
    .pag li:hover img {
      transform: scale(1.2); }

.news_item .more_btn {
  width: auto !important; }

.directory_page .bread_crumbs *:last-child, .all_proj .bread_crumbs *:last-child {
  color: #fff; }

.bread_crumbs {
  display: flex;
  align-items: center;
  padding: 20px 50px;
  padding-bottom: 5px;
  font-size: 12px;
  line-height: 15px;
  color: #828282; }
  .bread_crumbs > *:not(:first-child) {
    display: block;
    position: relative;
    padding-left: 10px; }
  .bread_crumbs > :first-child:after {
    display: none; }
  .bread_crumbs > * {
    margin-right: 10px; }
  .bread_crumbs *:after {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 3px);
    width: 4px;
    height: 4px;
    background: #BDBDBD;
    transform: rotate(45deg); }
  .bread_crumbs *:last-child {
    color: #333; }
    .bread_crumbs *:last-child:after {
      background: #EFC94F; }

.article_page {
  background-color: #fff; }
  .article_page h3 {
    color: #333;
    margin-bottom: 64px;
    margin-top: 30px; }
  .article_page .ornage_btn {
    margin-top: 40px;
    margin-bottom: 70px; }

.section_title_sm {
  position: relative; }
  .section_title_sm:after {
    bottom: -10px; }

h3 {
  font-weight: bold;
  font-size: 36px;
  line-height: 42px; }

.article_content {
  color: #333;
  font-size: 18px;
  line-height: 130%; }
  .article_content .img_wrap {
    margin: 30px 0; }
  .article_content .section_title_sm {
    padding-bottom: 1px;
    margin-bottom: 25px; }
  .article_content p {
    margin-bottom: 20px; }

.img_wrap {
  position: relative;
  max-width: 100%;
  overflow: hidden; }
  .img_wrap img {
    margin: 0 auto; }

.article_title {
  font-size: 24px;
  line-height: 110%;
  margin-top: 60px; }

.article_page .services {
  padding-top: 100px;
  background-color: #F2F2F2; }
  .article_page .services::after, .article_page .services:before {
    display: none; }

.article_page .section_title_sm {
  color: #333; }

.article_page .services_item {
  width: 250px;
  height: auto;
  text-align: center;
  background-color: transparent;
  box-shadow: none; }
  .article_page .services_item p {
    margin-top: 25px; }
  .article_page .services_item p:last-child {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    margin-top: 10px;
    font-weight: 400; }

.article_page .calc_section {
  margin-top: 100px;
  background-color: #F2F2F2; }

.section_title_sm p {
  margin-bottom: 0; }

.interesting {
  background-color: #F2F2F2;
  padding: 80px 0; }
  .interesting .section_title_sm h3 {
    margin-top: 0; }
  .interesting_block {
    padding: 40px 8%; }
    .interesting_block p:last-child {
      font-weight: bold;
      font-size: 30px;
      line-height: 54px;
      text-transform: uppercase;
      max-width: 300px;
      margin-top: 30px; }
    .interesting_block, .interesting_block .news_item_white {
      color: #000; }

.interesting_block_yelow {
  background-color: #EBD386;
  color: #000; }

.all_proj .item_link .item_link_dsc {
  bottom: 0; }

.all_proj .new_proj {
  padding-bottom: 0; }

.all_proj .show_more {
  margin-top: 70px; }

.all_proj .seo_block {
  margin-top: 15px;
  padding-top: 80px; }

.all_proj .section_seo {
  background-color: #333333; }

.all_proj .filter_select {
  width: 260px; }

.all_proj .filter_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 33%;
  font-weight: bold;
  border: 1px solid #FFFFFF; }
  .all_proj .filter_btns:first-child {
    border-right: none; }
  .all_proj .filter_btns:last-child {
    border-left: none; }
  .all_proj .filter_btns_wrap {
    display: flex;
    width: 70%; }

.all_proj .seo_content {
  max-height: 480px; }

.filter_select .jq-selectbox__select {
  height: 60px;
  background: none; }

.filter_select .jq-selectbox__select-text {
  line-height: 60px; }

.all_proj .new_proj {
  padding-top: 0; }

.filter_btns.active {
  background-color: #EFC94F;
  color: #000;
  border: 1px solid #EFC94F; }

.filter_btns:not(.active):hover {
  color: #EFC94F; }

.done_proj_page .item_link_dsc p {
  margin-bottom: 0; }

.all_proj_fix .item_link_dsc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 20px  !important;
  height: 50px; }
  .all_proj_fix .item_link_dsc p {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 18px; }
  .all_proj_fix .item_link_dsc span {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: #EFC94F;
    padding: 0 20px;
    color: #000; }

.more_filters {
  border-top: 1px solid #333333;
  padding: 50px 0; }
  .more_filters li {
    margin-bottom: 20px;
    color: #828282; }
    .more_filters li a:hover {
      color: #EFC94F; }

.sm_slide .img_wrap {
  height: 120px;
  padding: 0 15px; }
  .sm_slide .img_wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 0.4s; }

.sm_slide_content:before {
  content: '';
  position: absolute;
  width: calc(100% - 30px);
  height: 100%;
  left: 15px;
  top: 0;
  background: rgba(19, 27, 34, 0.7);
  display: none; }

.sm_slide.slick-current .sm_slide_content:before {
  display: block; }

.slide .img_wrap {
  height: 700px;
  width: 100%; }
  .slide .img_wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.sm_slider_wrap {
  margin: 20px -15px; }

.plan_img {
  margin-top: 20px; }

.sm_slide img:hover {
  transform: scale(1.2); }

.single_proj_dscr_item {
  max-width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .single_proj_dscr_item p:first-child {
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.3em;
    color: #4F4F4F; }
  .single_proj_dscr_item p {
    margin-top: 15px;
    color: #000;
    font-size: 18px;
    line-height: 130%; }

.single_proj_dscr {
  padding-top: 70px;
  background-color: #F2F2F2;
  padding-bottom: 80px; }

.single_proj_text {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  margin-top: 50px; }
  .single_proj_text p {
    margin-bottom: 20px; }

.more_inf {
  background-image: url("../img/moreinf.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 100px 0; }
  .more_inf::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 0; }
  .more_inf form {
    position: relative;
    z-index: 1; }
    .more_inf form > * {
      width: 30%; }
  .more_inf input:not(.ornage_btn) {
    height: 50px;
    border: 1px solid #fff;
    padding-left: 15px;
    color: #fff; }
    .more_inf input:not(.ornage_btn)::placeholder {
      color: #fff; }
  .more_inf .ornage_btn {
    margin: 0;
    font-family: 'Montserrat';
    letter-spacing: 0.3em; }
  .more_inf h3 {
    margin: 0;
    color: #fff; }
  .more_inf .section_title_sm {
    position: relative;
    z-index: 1;
    margin-bottom: 90px; }

.interest_items {
  padding: 80px 15px; }
  .interest_items h3 {
    margin-bottom: 30px; }
  .interest_items .item_link {
    max-height: 300px; }
    .interest_items .item_link_dsc {
      padding: 15px 10%; }

.done_signle_proj .single_proj_dscr_item {
  margin-bottom: 40px; }

.done_signle_proj .single_proj_dscr_row {
  flex-wrap: wrap; }

.about .first_view {
  padding-top: 20px;
  padding-bottom: 70px; }
  .about .first_view .bread_crumbs {
    position: relative;
    z-index: 2; }
    .about .first_view .bread_crumbs *:last-child {
      color: #fff; }
  .about .first_view .section_title {
    color: #fff;
    position: relative;
    z-index: 4; }
    .about .first_view .section_title_bg {
      color: #fff;
      z-index: 2;
      opacity: 0.2; }

.about {
  background-color: #fff;
  color: #000; }

.about_block_text {
  position: relative;
  margin-top: 70px;
  max-width: 280px;
  font-size: 18px;
  line-height: 24px; }
  .about_block_text_title {
    text-align: center;
    margin-top: 70px; }
  .about_block_text:after {
    content: '';
    position: absolute;
    left: -10px;
    top: -10px;
    width: 8px;
    height: 8px;
    background-color: #EFC94F;
    transform: rotate(-45deg); }

.our_people {
  padding-top: 70px; }
  .our_people .container-fluid {
    padding: 0 50px; }
  .our_people_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .our_people_img {
    position: relative;
    max-height: 365px;
    overflow: hidden;
    width: 100%; }
    .our_people_img img {
      margin: 0 auto; }
  .our_people_text {
    margin-top: 40px;
    text-align: center;
    font-family: 'Montserrat', sans-serif; }
    .our_people_text p:first-child {
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 5px; }
  .our_people_item {
    width: 20%;
    margin-bottom: 50px; }

.feedbacks {
  color: #fff;
  background-color: #000;
  padding-bottom: 70px; }

.video_feedback {
  width: 100%;
  height: 500px;
  margin-top: 70px; }

.video_dscr {
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
  padding-bottom: 15px;
  border-bottom: 1px solid #828282;
  width: 100%;
  margin-top: 20px;
  color: #828282;
  margin-bottom: 70px; }

.testimonial_item {
  padding: 25px 40px;
  background: #F2F2F2;
  box-shadow: 0px 2px 10px #333333;
  margin-bottom: 20px; }
  .testimonial_item_top {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    color: #333;
    padding-bottom: 15px;
    border-bottom: 1px solid #E0E0E0; }
  .testimonial_item_img {
    width: 65px;
    height: 65px;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    margin-right: 15px; }
  .testimonial_item_date {
    display: flex;
    color: #828282;
    align-items: flex-end;
    margin: 10px 0; }
    .testimonial_item_date span {
      display: block;
      margin-bottom: 3px;
      margin-left: 5px; }
  .testimonial_item_text {
    line-height: 160%;
    height: 130px;
    overflow: hidden; }
  .testimonial_item .more_text {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #828282;
    margin-top: 25px; }
    .testimonial_item .more_text span {
      cursor: pointer; }
      .testimonial_item .more_text span:hover {
        color: #333; }
    .testimonial_item .more_text span:last-child {
      display: none; }

.testimonials {
  background-image: url("../img/tsbg.png");
  background-position: top left;
  background-repeat: no-repeat;
  padding-top: 90px;
  background-color: #000; }

.full {
  height: auto !important; }

.more_text.full_show span:first-child {
  display: none; }

.more_text.full_show span:last-child {
  display: block; }

.about .pag {
  color: #fff; }

.pag span {
  color: #EFC94F; }

.thanks .first_view {
  color: #fff;
  text-align: center; }
  .thanks .first_view .container {
    position: relative;
    z-index: 2; }
  .thanks .first_view .section_title_sm::after {
    left: 50%;
    transform: translate(-50%, 0); }

.thanks .video_feedback {
  margin-bottom: 90px; }

.first_thanks_text {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  line-height: 140%;
  margin-top: 30px; }

.future_proj {
  display: flex;
  background-color: #000;
  color: #fff;
  overflow: hidden; }
  .future_proj_paralax {
    width: 50vw;
    position: relative; }
  .future_proj_content {
    padding-top: 120px;
    padding-bottom: 170px;
    width: 40vw;
    font-size: 20px;
    line-height: 170%;
    color: #BDBDBD; }
    .future_proj_content p {
      margin-bottom: 20px; }
    .future_proj_content_price span:first-child {
      font-weight: bold;
      font-size: 24px; }
    .future_proj_content .transparent_btn {
      margin-top: 70px;
      margin-left: 0; }
      .future_proj_content .transparent_btn span {
        color: #fff; }
  .future_proj .section_title_sm {
    margin-bottom: 70px;
    color: #fff; }

.scene {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0; }
  .scene li:first-child div {
    bottom: 150px;
    left: 2%;
    position: absolute;
    max-width: 80%;
    overflow: hidden; }
  .scene li:last-child div {
    max-width: 80%;
    overflow: hidden;
    bottom: 0;
    left: 0%;
    position: absolute; }

.services_page .first_view p {
  position: relative;
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  line-height: 160%;
  z-index: 2;
  margin-bottom: 30px; }

.services_page_content {
  padding-top: 20px; }

.services_page_table {
  padding-top: 60px;
  background-color: #F2F2F2;
  margin-top: 40px; }
  .services_page_table ul {
    margin-bottom: 70px; }
  .services_page_table li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #4F4F4F;
    padding: 12px 30px;
    position: relative;
    border-bottom: 1px solid #E0E0E0; }
    .services_page_table li span:last-child {
      display: block;
      min-width: 102px; }
    .services_page_table li:after {
      content: '';
      position: absolute;
      left: 0;
      top: calc(50% - 4px);
      width: 8px;
      height: 8px;
      background: #BDBDBD;
      transform: rotate(45deg); }

.fnt_24 {
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 40px; }

.price_section {
  display: flex;
  justify-content: space-between;
  padding: 20px 50px; }
  .price_section_item {
    display: flex; }
    .price_section_item_img {
      position: relative;
      overflow: hidden;
      margin-right: 10px; }
    .price_section_item_text p {
      font-weight: bold;
      font-size: 18px;
      line-height: 130%;
      color: #4F4F4F;
      margin-bottom: 25px; }
    .price_section_item_text ul {
      margin-bottom: 0; }
    .price_section_item_text li {
      padding: 10px 30px;
      border-bottom: none;
      font-size: 14px;
      line-height: 120%; }

.services_page_text_section {
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 30px; }
  .services_page_text_section p {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 140%; }
  .services_page_text_section .fnt_24 {
    margin-top: 50px;
    margin-bottom: 30px; }
  .services_page_text_section .ornage_btn {
    margin-top: 80px;
    margin-bottom: 30px; }

.map {
  position: relative;
  overflow: hidden;
  height: 300px;
  width: 100%; }
  .map img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    object-position: center; }
  .map .bread_crumbs {
    position: absolute;
    left: 0;
    top: 0; }

.contacts_item {
  margin-top: 70px;
  max-width: 400px; }
  .contacts_item > * {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px; }
  .contacts_item .contacts_item_sm {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    margin-bottom: 10px; }

.contacts_page_content {
  padding-bottom: 80px; }
  .contacts_page_content > .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
  .contacts_page_content form {
    margin-top: -100px;
    background-color: #fff;
    padding: 90px 70px 56px 70px;
    width: 600px;
    position: relative;
    z-index: 2; }
    .contacts_page_content form input, .contacts_page_content form textarea {
      width: 100%;
      border-bottom: 1px solid #4F4F4F;
      color: #000 !important;
      height: 45px;
      appearance: initial;
      margin-bottom: 30px;
      font-size: 12px; }
      .contacts_page_content form input::placeholder, .contacts_page_content form textarea::placeholder {
        font-family: 'Montserrat';
        font-size: 12px; }
    .contacts_page_content form textarea {
      height: auto; }
  .contacts_page_content .ornage_btn {
    background-color: #000;
    color: #fff !important; }

.form_title {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.3em;
  color: #000;
  margin-bottom: 80px;
  text-align: center; }

.footer_contacts_page {
  background-color: #181818; }

.exit_popup_wrap {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 20000;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.98) 7.81%, rgba(0, 0, 0, 0.95) 100%);
  display: flex;
  justify-content: center;
  align-items: center; }

.exit_popup {
  width: 600px;
  height: auto;
  max-height: 80vh;
  overflow: scroll; }
  .exit_popup_title {
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
    color: #EFC94F;
    margin-bottom: 20px; }
  .exit_popup ul {
    padding-left: 8px; }
  .exit_popup li {
    font-family: 'Montserrat';
    font-size: 24px;
    line-height: 46px;
    color: #FFFFFF;
    position: relative;
    padding-left: 15px; }
    .exit_popup li:after {
      content: '';
      position: absolute;
      left: 0;
      top: 19px;
      width: 8px;
      height: 8px;
      background: #F5CB45;
      transform: rotate(45deg); }
  .exit_popup_text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px; }
  .exit_popup_img {
    position: relative;
    overflow: hidden;
    margin: 20px 0; }
    .exit_popup_img img {
      width: 100%;
      height: auto;
      margin: 0 auto; }
  .exit_popup .ornage_btn, .exit_popup .transparent_btn {
    width: 100%;
    margin-top: 20px; }
  .exit_popup .transparent_btn span {
    color: #fff; }
  .exit_popup_close {
    position: absolute;
    right: 50px;
    top: 50px;
    cursor: pointer; }

.consult_popup {
  border: none;
  border-radius: 0; }
  .consult_popup .modal-header .close {
    margin: -1rem  auto -1rem -1rem; }
    .consult_popup .modal-header .close img {
      width: 20px;
      height: 20px; }
  .consult_popup form {
    width: 100%;
    padding: 0 10% 50px 10%; }
    .consult_popup form p {
      font-weight: bold;
      font-size: 30px;
      line-height: 46px;
      color: #000;
      margin-bottom: 40px;
      text-transform: uppercase; }
    .consult_popup form input:not([type='submit']) {
      width: 100%;
      height: 50px;
      border: 1px solid #4F4F4F;
      margin-bottom: 40px;
      display: block;
      padding-left: 10px; }
  .consult_popup .modal-header {
    border: none; }

.consult_popup_wrap .modal-dialog {
  max-width: 600px !important; }

@media (max-height: 750px) {
  .menu li {
    font-size: 28px;
    line-height: 34px; } }

@media (max-width: 1700px) and (min-width: 1600px) {
  .section_title_bg {
    left: -25px; } }

@media (max-width: 1599.9px) {
  main {
    padding-top: 80px; }
  .sticky {
    top: 100px; }
  .contacts_page_content form {
    width: 550px; }
  .slide .img_wrap {
    height: 500px; }
  .directory_page .news_item_title {
    line-height: 40px; }
  body {
    font-size: 12px; }
  .first_view_content p {
    font-size: 50px;
    line-height: 62px; }
  header .container-fluid > div:last-child {
    font-size: 20px;
    line-height: 120%; }
  .section_title_bg {
    font-size: 110px; }
  .section_title_sm {
    font-size: 40px; }
    .section_title_sm::after {
      width: 80px; }
  .transparent_btn, .ornage_btn {
    width: 500px;
    max-width: 100%; }
  .services_item {
    width: 200px;
    height: 200px; }
  .services_item_icon {
    width: 80px; }
  .calc {
    max-width: 520px;
    width: auto;
    padding: 30px 70px; }
  .calc_section {
    padding: 30px 0; }
  .calc_title {
    font-size: 40px; }
  .calc_sub_title {
    font-size: 16px; }
  .calc_steps, .calc .jqselect, .calc input {
    margin-bottom: 30px; }
  .new_proj {
    padding-bottom: 70px; }
  .done_proj {
    padding-top: 150px; }
  .home_news .news_item {
    max-width: 450px; }
  .news_item_title {
    font-size: 30px;
    margin: 20px 0; }
  .seo_block {
    margin-top: 100px; }
  .seo_content {
    height: 200px;
    font-size: 14px; }
  .footer_contacts {
    font-size: 14px; }
  .lang_and_link {
    font-size: 16px; }
  header {
    height: 80px; }
  .menu_btn img {
    height: 30px;
    margin: 0 auto; }
  .menu_btn_wrap p {
    font-size: 11px;
    line-height: 110%;
    margin-top: 5px; }
  .first_view {
    padding-top: 60px;
    padding-bottom: 90px; }
  .footer_main {
    padding: 40px 60px; }
  .directory li {
    font-size: 14px; }
  .directory > p {
    margin-bottom: 20px; }
  .article_content {
    font-size: 14px; }
  .item_link_dsc div {
    font-size: 14px; }
  .filter_select .jq-selectbox__select {
    height: 50px; }
  .filter_select .jq-selectbox__select-text {
    line-height: 50px; }
  .all_proj .filter_btns {
    height: 50px; }
  .testimonial_item_top {
    font-size: 15px; }
  .testimonial_item {
    padding: 20px; }
  .our_people_text p:first-child {
    font-size: 18px; }
  .fnt_24, .services_page .first_view p {
    font-size: 22px; }
  .all_proj_fix .item_link_dsc {
    height: 40px; }
    .all_proj_fix .item_link_dsc p {
      font-size: 16px; }
    .all_proj_fix .item_link_dsc span {
      height: 40px;
      padding: 0 10px; }
  .exit_popup_title {
    font-size: 30px;
    line-height: 35px; }
  .exit_popup li {
    font-size: 17px;
    line-height: 25px; }
  .exit_popup_text {
    font-size: 18px;
    line-height: 25px; }
  .exit_popup_img {
    max-width: 60%;
    margin: 0 auto; }
  .single_proj_dscr_item p {
    font-size: 14px; }
  .single_proj_dscr_item p:first-child {
    font-size: 12px; } }

@media (max-width: 1450px) {
  .contacts_page_content form {
    width: 500px;
    padding: 80px 40px 46px 40px; }
  .services_page_text_section {
    padding-top: 20px; }
  .services_page .first_view p {
    font-size: 19px;
    margin-bottom: 20px; }
  .thanks .video_feedback {
    margin: 40px 0; }
  .future_proj_content {
    width: 45vw; }
  .future_proj_content {
    padding: 70px 0; }
  .video_feedback {
    height: 400px; }
  .slide .img_wrap {
    height: 400px; }
  .section_title {
    height: 140px; }
  .all_proj .filter_select {
    width: 200px; }
  .section_title_bg {
    font-size: 90px;
    left: -35px; }
  .section_title_sm {
    font-size: 33px; }
  .home_page > section:not(.home_news) {
    padding: 50px 0; }
  .seo_block {
    margin: 80px 0; }
  .footer_contacts_item {
    padding-bottom: 40px; }
  .first_view_content p {
    font-size: 40px;
    max-width: 600px; }
  .side_nav {
    right: 20px; }
  .header_lang {
    margin-right: 70px; }
  .header_lang span:first-child {
    margin-right: 20px; }
  header .container-fluid > div:last-child {
    font-size: 16px; }
  .header_tel {
    height: 20px; }
    .header_tel img {
      height: 100%; }
  .header_logo {
    height: 40px;
    margin-left: 50px; }
    .header_logo img {
      height: 100%; }
  .transparent_btn span, .ornage_btn span {
    font-size: 13px; }
  .items_row {
    grid-template-columns: repeat(3, 1fr); }
  .items_row > div:nth-of-type(8n+4), .items_row > div:nth-of-type(8n+6) {
    transform: none; }
  .items_row > div:nth-of-type(8n+5) {
    transform: translate(0px, -70px); }
  .interesting_block p:last-child {
    font-size: 23px;
    line-height: 32px; }
  h3 {
    font-size: 24px;
    line-height: 130%; }
  .article_title {
    font-size: 20px; }
  .fnt_24 {
    font-size: 20px !important; } }

@media (max-width: 1199.9px) {
  .item_link_dsc {
    bottom: 0 !important; }
  main {
    padding-top: 0px; }
  header {
    position: static; }
  .menu {
    position: absolute; }
  .contacts_page_content {
    padding-top: 30px; }
  .services_page .first_view p {
    font-size: 16px; }
  .price_section {
    flex-direction: column;
    align-items: center; }
    .price_section > div {
      margin-bottom: 40px; }
  .video_feedback {
    height: 350px; }
  .feedbacks {
    padding-top: 30px; }
  .our_people_item {
    width: 33%; }
  .about_block_text {
    font-size: 14px;
    margin: 0 auto;
    margin-top: 40px; }
  .interest_items {
    padding: 30px 15px; }
  .item_link_dsc div {
    font-size: 12px; }
  .section_title {
    height: auto; }
    .section_title_bg {
      display: none; }
  .side_nav, .header_lang {
    display: none; }
  .first_view ul {
    margin-right: 50px; }
  .transparent_btn span, .ornage_btn span {
    font-size: 11px; }
  .directory_page .news_item_title {
    font-size: 22px;
    line-height: 25px; }
  .directory_page .news_item {
    padding: 30px 15px; }
  .interesting_block p:last-child {
    font-size: 18px;
    line-height: 22px; } }

@media (max-width: 991.9px) {
  .lang_and_link > a:last-child {
    margin-top: 0px; }
  .more_filters ul {
    margin-top: 40px; }
  .contacts_page_content > .container {
    flex-direction: column; }
  .contacts_page_content form {
    margin: 0 auto;
    margin-top: 40px; }
  .more_inf input:not(.ornage_btn), .more_inf .ornage_btn {
    height: 40px;
    font-size: 12px; }
  .slide .img_wrap {
    height: 340px; }
  .more_inf {
    padding: 60px 0; }
  .item_link_dsc div {
    font-size: 12px; }
  .item_link_dsc {
    padding: 15px; }
  .calc_section_img {
    max-width: 100vw; }
  .calc_section .container {
    justify-content: center; }
  .news_item_title {
    font-size: 25px; }
  .news_item .more_btn {
    margin-top: 25px; }
  .news_item {
    padding: 30px 20px;
    width: 100%; }
  .home_news .col-lg-6:nth-child(2) {
    margin-top: -30px; }
  .footer_contacts {
    flex-wrap: wrap; }
    .footer_contacts_item {
      width: 100%; }
  .footer_main {
    flex-wrap: wrap; }
  .lang_and_link {
    margin-top: 40px;
    width: 100%; }
  .lang_and_link {
    display: flex;
    justify-content: space-between; }
  .menu .transparent_btn {
    width: 350px; }
  .directory_page .container-fluid {
    padding: 0 15px; }
  .directory_page .news_item {
    padding: 20px 15px; }
  .directory_page .news_item .more_btn span {
    font-size: 10px; }
  .directory_page .news_item_title {
    font-size: 19px;
    line-height: 23px; }
  .article_page .services_row > div {
    width: 50%; }
  .interesting_block {
    margin-bottom: 20px; } }

@media (max-width: 767.9px) {
  .consult_popup form p {
    font-size: 22px; }
  .exit_popup {
    width: calc(100vw - 60px); }
  .promo_imgs div {
    margin-top: 30px; }
  .services_page .first_view p {
    font-size: 14px; }
  .video_feedback {
    height: 270px; }
  .about_block_text_title {
    font-size: 22px; }
  .our_people_item {
    width: 50%; }
  .slide .img_wrap {
    height: 300px; }
  .more_inf form {
    flex-wrap: wrap; }
    .more_inf form > * {
      width: 100%;
      margin-bottom: 30px; }
  .more_inf .section_title_sm {
    margin-bottom: 40px; }
  .projects_filters {
    flex-wrap: wrap; }
  .all_proj .filter_btns_wrap {
    margin-bottom: 30px;
    width: 100%; }
  .header_tel {
    display: none; }
  header .align_center:first-child {
    width: 100%;
    justify-content: space-between; }
  header {
    padding: 0; }
  .first_view_content_bottom {
    flex-wrap: wrap; }
    .first_view_content_bottom .ornage_btn {
      margin: 0;
      margin-top: 30px; }
  .first_view ul li {
    font-size: 18px;
    padding-left: 20px; }
    .first_view ul li:after {
      width: 5px;
      height: 5px; }
  .first_view_content p {
    font-size: 30px;
    line-height: 140%;
    max-width: 400px; }
  .services_item {
    width: 150px;
    height: 150px;
    margin-top: 30px; }
  .services::after {
    height: 200px; }
  .services_item_icon {
    width: 60px; }
  .services_item p {
    font-size: 16px;
    margin-top: 20px; }
  .home_news .news_item {
    margin: 0 auto;
    max-width: 360px;
    margin-top: 30px; }
  .home_news .col-lg-6:nth-child(2) {
    margin: 0; }
  .seo_content {
    padding-right: 20px;
    height: 150px; }
  .directory {
    margin-top: 40px; }
  .footer_logo_wrap p {
    display: none; }
  .footer_main {
    padding: 30px 20px; }
  .footer_lang {
    margin-bottom: 0; }
  .menu_lang {
    position: absolute;
    right: 15px;
    top: 50px; }
  .menu .close_btn {
    left: 15px; }
  .menu_content {
    flex-direction: column;
    align-items: flex-start; }
  .menu {
    padding-top: 150px;
    padding-left: 15px;
    align-items: flex-start; }
  .menu .transparent_btn {
    margin-top: 30px; }
  .menu li {
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 20px; }
  .menu {
    min-height: 100vh;
    height: auto;
    padding-bottom: 20px; }
  .items_row {
    grid-template-columns: repeat(2, 1fr); }
  .items_row > div:nth-of-type(8n+4), .items_row > div:nth-of-type(8n+6) {
    transform: translate(0, -70px); }
  .items_row > div:nth-of-type(8n+5) {
    transform: none; }
  .single_proj_dscr_item {
    max-width: none;
    width: 50%; } }

@media (max-width: 575.9px) {
  .lang_and_link {
    flex-wrap: wrap; }
    .lang_and_link .footer_lang {
      width: 100%;
      margin-bottom: 20px; }
  .exit_popup_wrap {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.931) 0%, rgba(0, 0, 0, 0.9025) 100%); }
  .consult_popup form p {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 27px; }
  .consult_popup form {
    padding: 0 0 30px 0; }
  .consult_popup .ornage_btn {
    font-size: 14px; }
  .consult_popup form input:not([type='submit']) {
    margin-bottom: 25px;
    height: 45px; }
  .exit_popup_close {
    top: 20px;
    right: 20px; }
  .exit_popup_title {
    font-size: 22px;
    line-height: 35px; }
  .exit_popup li {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 10px; }
  .exit_popup_text {
    font-size: 17px;
    line-height: 25px; }
  .exit_popup li:after {
    top: 8px; }
  .promo_page {
    padding-top: 30px; }
  .contacts_item > * {
    font-size: 18px; }
  .contacts_item {
    margin-top: 40px; }
  .contacts_page_content form {
    width: 100%;
    padding: 40px 20px; }
  .form_title {
    font-size: 18px;
    margin-bottom: 30px; }
  .our_people_text p:first-child {
    font-size: 16px;
    line-height: 140%; }
  .our_people_text {
    margin-top: 20px; }
  .about_block_text_title {
    font-size: 18px;
    margin-top: 30px; }
  .our_people .container-fluid {
    padding: 0 15px; }
  .done_signle_proj .single_proj_dscr_item {
    width: 100%;
    margin-bottom: 20px; }
  .single_proj {
    padding-top: 20px; }
  .slide .img_wrap {
    height: 250px; }
  .single_proj_dscr {
    padding: 40px 0; }
  .single_proj_dscr h3 {
    margin-bottom: 30px; }
  .single_proj_dscr .just_btwn {
    flex-wrap: wrap; }
  .single_proj_dscr_item {
    width: 50%;
    max-width: none;
    margin-bottom: 20px; }
  .calc {
    max-width: 100%;
    padding: 30px 15px; }
  .services_row {
    flex-wrap: wrap; }
  .calc_title {
    font-size: 25px; }
  .calc_sub_title {
    font-size: 12px;
    margin: 15px 0; }
  .calc_steps > div span {
    width: 20px;
    height: 20px;
    font-size: 12px; }
  .calc_steps > div:before {
    right: 30px; }
  .calc_steps > div:after {
    left: 30px; }
  .calc .jq-selectbox__select {
    height: 40px;
    padding-right: 30px; }
  .calc .jq-selectbox__select-text {
    line-height: 40px; }
  .jq-selectbox__select {
    font-size: 12px; }
  .calc .jq-selectbox__trigger {
    width: 30px; }
  .transparent_btn, .ornage_btn {
    height: 40px; }
  .section_title_sm {
    font-size: 27px;
    line-height: 34px; }
  .news_item_title {
    font-size: 22px; }
  .footer_contacts {
    font-size: 12px; }
  .lang_and_link {
    font-size: 14px; }
  .menu_lang {
    font-size: 18px; }
  .footer_lang span:first-child, .menu_lang span:first-child {
    margin-right: 15px; }
  .menu .transparent_btn {
    width: 270px; }
  .calc input {
    height: 40px; }
  .calc_steps > div span img {
    width: 10px; }
  .menu {
    min-height: unset;
    padding-top: 100px;
    height: 100vh; }
  .menu .close_btn, .menu_lang {
    top: 30px; }
  .services_row > div {
    width: 50%; }
  .services_item {
    width: 120px;
    height: 120px; }
  .article_title {
    font-size: 18px; }
  .bread_crumbs {
    display: none; }
  .article_page .services_row > div {
    width: 100%; }
  .services_page_table li {
    flex-direction: column;
    text-align: center; }
  .price_section_item {
    flex-direction: column;
    justify-content: center; }
    .price_section_item_text li {
      text-align: left; }
  .sm_slider_wrap {
    width: 100%;
    overflow: hidden;
    margin: 20px 0; } }

@media (max-width: 500px) {
  .items_row {
    grid-template-columns: repeat(1, 1fr); }
  .directory_page .news_item {
    transform: none !important; }
  .directory_page .news_item {
    padding: 40px 15px; }
  .items_row {
    padding-top: 40px; }
  .all_proj .filter_btns {
    font-size: 9px;
    height: 35px; }
  .filter_select .jq-selectbox__select {
    height: 35px; }
  .filter_select .jq-selectbox__select-text {
    line-height: 35px; } }
