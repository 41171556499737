@media(max-height:750px){
    .menu li{
        font-size: 28px;
        line-height: 34px;
    }
}
@media(max-width:1700px) and(min-width:1600px){
    .section_title_bg{
        left: -25px;
    }
}
@media(max-width:1599.9px){
    main{
        padding-top: 80px;
    }
    .sticky{
        top: 100px;
    }
    .contacts_page_content form{
        width: 550px;
    }
    .slide .img_wrap{
        height: 500px;
    }

    .directory_page .news_item_title{
        line-height: 40px;
    }
    body{
        font-size: 12px;
    }
    .first_view_content p{
        font-size: 50px;
        line-height: 62px;
    }
    header .container-fluid > div:last-child{      
          font-size: 20px;
          line-height: 120%;
    }
    .section_title_bg{
        font-size: 110px;
    }
    .section_title_sm{
        font-size: 40px;
        &::after{
            width: 80px;
        }
    }
    .transparent_btn,.ornage_btn{
        width: 500px;
        max-width: 100%;
    }
    .services_item{
        width: 200px;
        height: 200px;
    }
    .services_item_icon{
        width: 80px;
    }
    .calc{
        max-width: 520px;
        width: auto;
        padding: 30px 70px;
    }
    .calc_section{
        padding: 30px 0;
    }
    .calc_title{
        font-size: 40px;
    }
    .calc_sub_title{
        font-size: 16px;
    }
    .calc_steps,.calc .jqselect,  .calc input{
        margin-bottom: 30px;
    }
    .new_proj{
        padding-bottom: 70px;
    }
    .done_proj{
        padding-top: 150px;
    }
    .home_news .news_item{
        max-width: 450px;
    }
    .news_item_title{
        font-size: 30px;
        margin: 20px 0;
    }
    .seo_block{
        margin-top: 100px;
    }
    .seo_content{
        height: 200px;
        font-size: 14px;
    }
    .footer_contacts{
        font-size: 14px;
    }
    .lang_and_link{
        font-size: 16px;
    }
    header{
        height: 80px;
    }
    .menu_btn img{
        height: 30px;
        margin: 0 auto;
    }
    .menu_btn_wrap p{
        font-size: 11px;
        line-height: 110%;
        margin-top: 5px;
    }
    .first_view{
        padding-top: 60px;
        padding-bottom: 90px;
    }
    .footer_main{
        padding: 40px 60px;
    }
    .directory li {
        font-size: 14px;
    }
    .directory > p{
        margin-bottom: 20px;
    }
    .article_content{
        font-size: 14px;
    }
    .item_link_dsc div{
        font-size: 14px;
    }
    .filter_select .jq-selectbox__select{
        height: 50px;
    }
    .filter_select .jq-selectbox__select-text{
        line-height: 50px;
    }
    .all_proj .filter_btns{
        height: 50px;
    }
    .testimonial_item_top{
        font-size: 15px;
    }
    .testimonial_item{
        padding: 20px;
    }
    .our_people_text p:first-child{
        font-size: 18px;
    }
    .fnt_24,.services_page .first_view p{
        font-size: 22px;
    }
    .all_proj_fix{
        .item_link{
            &_dsc{
                height: 40px;
                p{
                    font-size: 16px;
                }
                span{
                    height: 40px;
                    padding: 0 10px;
                }
            }
        }
    }
    .exit_popup{
        &_title{
            font-size: 30px;
            line-height: 35px;
        }
    }
    .exit_popup li{
        font-size: 17px;
        line-height: 25px;
    }
    .exit_popup_text{
        font-size: 18px;
        line-height: 25px;
    }
    .exit_popup_img{
        max-width: 60%;
        margin: 0 auto;
    }
    .single_proj_dscr_item p{
        font-size: 14px;
    }
    .single_proj_dscr_item p:first-child{
        font-size: 12px;
    }
}
@media(max-width:1450px){
    .contacts_page_content form{
        width: 500px;
        padding: 80px 40px 46px 40px;
    }
    .services_page_text_section{
        padding-top: 20px;
    }
    .services_page .first_view p{
        font-size: 19px;
        margin-bottom: 20px;
    }
    .thanks .video_feedback{
        margin: 40px 0;
    }
    .future_proj_content{
        width: 45vw;
    }
    .future_proj_content{
        padding: 70px 0;
    }
    .video_feedback{
        height: 400px;
    }
    .slide .img_wrap {
        height: 400px;
    }
    .section_title{
        height: 140px;
    }
    .all_proj .filter_select {
        width: 200px;
    }
    .section_title_bg{
        font-size: 90px;
        left: -35px;
    }
    .section_title_sm{
        font-size: 33px;
    }
    .home_page > section:not(.home_news){
        padding: 50px 0;
    }
    .seo_block{
        margin: 80px 0;
    }
    .footer_contacts_item{
        padding-bottom: 40px;
    }
    .first_view_content p{
        font-size: 40px;
        max-width: 600px;
    }
    .side_nav{
        right: 20px;
    }
    .header_lang{
        margin-right: 70px;
    }
    .header_lang span:first-child{
        margin-right: 20px;
    }
    header .container-fluid > div:last-child{     
           font-size: 16px;
    }
    .header_tel{
        height: 20px;
        img{
            height: 100%;
        }
    }
    .header_logo{
        height: 40px;
        margin-left: 50px;
        img{
            height: 100%;
        }
    }
    .transparent_btn, .ornage_btn{
        span{
            font-size: 13px;
        }
    }
    .items_row{
        grid-template-columns: repeat(3, 1fr);
    }
    .items_row > div:nth-of-type(8n+4),.items_row > div:nth-of-type(8n+6){
        transform: none;
    }
    .items_row > div:nth-of-type(8n+5){
        transform: translate(0px, -70px);
    }
    .interesting_block p:last-child{
        font-size: 23px;
        line-height: 32px;
    }
    h3{
        font-size: 24px;
        line-height: 130%;
    }
    .article_title{
        font-size: 20px;
    }
    .fnt_24{
        font-size: 20px !important;
    }
}
@media(max-width:1199.9px){
    .item_link_dsc{
        bottom: 0 !important;
    }
    main{
        padding-top: 0px;
    }
        header{
            position: static;
        }
        .menu{
            position: absolute;
        }
    .contacts_page_content{
        padding-top: 30px;
    }
    .services_page .first_view p{
        font-size: 16px;
    }
    .price_section{
        flex-direction: column;
        align-items: center;
        >div{
            margin-bottom: 40px;
        }
    }
    .video_feedback{
        height: 350px;
    }
    .feedbacks{
        padding-top: 30px;
    }
    .our_people_item{
        width: 33%;
    }
    .about_block_text{
        font-size: 14px;
        margin: 0 auto;
        margin-top: 40px;
    }
    .interest_items{
        padding: 30px 15px;
    }
    .item_link_dsc div{
        font-size: 12px;
    }
    .section_title{
        height: auto;
        &_bg{
            display: none;
        }
    }
    .side_nav,.header_lang{
        display: none;
    }
    .first_view ul{
        margin-right: 50px;
    }
    .transparent_btn span, .ornage_btn span{
        font-size: 11px;
    }
    .directory_page .news_item_title{
        font-size: 22px;
        line-height: 25px;
    }
    .directory_page .news_item{
        padding: 30px 15px;
    }
    .interesting_block p:last-child{
        font-size: 18px;
        line-height: 22px;
    }
}
@media(max-width:991.9px){
    .lang_and_link{

        >a:last-child{
          margin-top: 0px;

        }
      }
    .more_filters{
        ul{
            margin-top: 40px;
        }
    }
    .contacts_page_content > .container{
        flex-direction: column;
    }
    .contacts_page_content{
        form{
            margin: 0 auto;
            margin-top: 40px;

        }
    }
    .more_inf input:not(.ornage_btn),.more_inf .ornage_btn{
        height: 40px;
        font-size: 12px;
    }
    .slide .img_wrap{
        height: 340px;
    }
    .more_inf{
        padding: 60px 0;
    }
    .item_link_dsc div{
        font-size: 12px;
    }
    .item_link_dsc{
        padding: 15px ;
    }
    .calc_section_img{
        max-width: 100vw;
    }
    .calc_section .container{
        justify-content: center;
    }
    .news_item_title{
        font-size: 25px;
    }
    .news_item .more_btn{
        margin-top: 25px;
    }
    .news_item{
        padding: 30px 20px; 
        width: 100%;
    }
    .home_news .col-lg-6:nth-child(2){
        margin-top: -30px;
    }
    .footer_contacts{
        flex-wrap: wrap;
        &_item{
            width: 100%;
        }
    }
    .footer_main{
        flex-wrap: wrap;
    }
    .lang_and_link{
        margin-top: 40px;
        width: 100%;
    }
    .lang_and_link{
        display: flex;
        justify-content: space-between;
    }
    .menu .transparent_btn{
        width: 350px;
    }
    .directory_page .container-fluid{
        padding: 0 15px;
    }
    .directory_page .news_item{
        padding: 20px 15px;
    }
    .directory_page .news_item .more_btn{
        span{
            font-size: 10px;
        }
    }
    .directory_page .news_item_title{
        font-size: 19px;
        line-height: 23px;
    }
  .article_page  .services_row > div{
        width: 50%;
    }
    .interesting_block{
        margin-bottom: 20px;
    }
}
@media(max-width:767.9px){
    .consult_popup form p{
        font-size: 22px;
    }
    .exit_popup{
        width: calc(100vw - 60px);
    }
    .promo_imgs{
        div{
            margin-top: 30px;
        }
    }
    .services_page .first_view p{
        font-size: 14px;
    }
    .video_feedback{
        height: 270px;
    }
    .about_block_text_title{
        font-size: 22px;
    }
    .our_people_item{
        width: 50%;
    }
    .slide .img_wrap{
        height: 300px;
    }
    .more_inf form{
        flex-wrap: wrap;
        >*{
            width: 100%;
            margin-bottom: 30px;
        }
    }
    .more_inf .section_title_sm{
        margin-bottom: 40px;
    }
    .projects_filters{
        flex-wrap: wrap;
    }
    .all_proj .filter_btns_wrap{
        margin-bottom: 30px;
        width: 100%;
    }
    .header_tel{
        display: none;
    }
    header .align_center:first-child{
        width: 100%;
        justify-content: space-between;
    }
    header{
        padding: 0;
    }
    .first_view_content_bottom{
        flex-wrap: wrap;
        .ornage_btn{
            margin: 0;
            margin-top: 30px;
        }
    }
    .first_view ul li{
        font-size: 18px;
        padding-left: 20px;
        &:after{
            width: 5px;
            height: 5px;
        }
    }
    .first_view_content p{
        font-size: 30px;
        line-height: 140%;
        max-width: 400px;
    }
    .services_item{
        width: 150px;
        height: 150px;
        margin-top: 30px;
    }
    .services::after{
        height: 200px;
    }
    .services_item_icon{
        width: 60px;
    }
    .services_item p{
        font-size: 16px;
        margin-top: 20px;
    }
    .home_news .news_item{
        margin: 0 auto;
        max-width: 360px;
        margin-top: 30px;
    }
    .home_news .col-lg-6:nth-child(2){
        margin: 0;
    }
    .seo_content{
        padding-right: 20px;
        height: 150px;
    }
    .directory{
        margin-top: 40px;
    }
    .footer_logo_wrap{
        p{
            display: none;
        }
    }
    .footer_main{
        padding: 30px 20px;
    }
    .footer_lang{
        margin-bottom: 0;
    }
    .menu_lang{
        position: absolute;
        right: 15px;
        top: 50px;
    }
    .menu .close_btn{
        left: 15px;
    }
    .menu_content{
        flex-direction: column;
        align-items: flex-start;
    }
    .menu{
        padding-top: 150px;
        padding-left: 15px;
        align-items: flex-start;
    }
    .menu .transparent_btn{
        margin-top: 30px;
    }
    .menu li{
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 20px;
    }
    .menu{
        min-height: 100vh;
        height: auto;
        padding-bottom: 20px;
    }
    .items_row{
        grid-template-columns: repeat(2, 1fr);
    }
    .items_row > div:nth-of-type(8n+4), .items_row > div:nth-of-type(8n+6){
        transform: translate(0, -70px);
    }
    .items_row > div:nth-of-type(8n+5){
        transform: none;
    }
    .single_proj_dscr_item{
        max-width: none;
        width: 50%;
    }
}
@media(max-width:575.9px){
    .lang_and_link{
        flex-wrap: wrap;
        .footer_lang{
            width: 100%;
            margin-bottom: 20px;
        }
    }
    .exit_popup_wrap{
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.931) 0%, rgba(0, 0, 0, 0.9025) 100%);
    }
    .consult_popup form p{
        font-size: 18px;
        margin-bottom: 20px;
        line-height: 27px;
    }
    .consult_popup form{
        padding: 0 0 30px 0;
    }
    .consult_popup{
        .ornage_btn{
            font-size: 14px;
        }
    }
    .consult_popup form input:not([type='submit']){
        margin-bottom: 25px;
        height: 45px;
    }
    .exit_popup_close{
        top: 20px;
        right: 20px;
    }
    .exit_popup{
        &_title{
            font-size: 22px;
            line-height: 35px;
        }
    }
    .exit_popup li{
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 10px;
    }
    .exit_popup_text{
        font-size: 17px;
        line-height: 25px;
    }
    .exit_popup li:after{
        top: 8px;
    }
    .promo_page{
        padding-top: 30px;
    }
    .contacts_item > *{
        font-size: 18px;
    }
    .contacts_item{
        margin-top: 40px;
    }
    .contacts_page_content{
        form{
            width: 100%;
            padding: 40px 20px; 
        }
    }
    .form_title{
        font-size: 18px;
        margin-bottom: 30px;
    }
    .our_people_text p:first-child{
        font-size: 16px;
        line-height: 140%;
    }
    .our_people_text{
        margin-top: 20px;
    }
    .about_block_text_title{
        font-size: 18px;
        margin-top: 30px;
    }
    .our_people .container-fluid{
        padding: 0 15px;
    }
    .done_signle_proj .single_proj_dscr_item{
        width: 100%;
        margin-bottom: 20px;
    }
    .single_proj{
        padding-top: 20px;
    }
    .slide .img_wrap{
        height: 250px;
    }
    .single_proj_dscr{
        padding: 40px 0;
    }
     .single_proj_dscr h3{
         margin-bottom: 30px;
     }
    .single_proj_dscr{
        .just_btwn{
            flex-wrap: wrap;
        }
        &_item{
            width: 50%;
            max-width: none;
            margin-bottom: 20px;
        }
    }
    .calc{
        max-width: 100%;
        padding:30px 15px;
    }
    .services_row {
        flex-wrap: wrap;
    }
    .calc_title{
        font-size: 25px;
    }
    .calc_sub_title{
        font-size: 12px;
        margin: 15px 0;
    }
    .calc_steps > div span{
        width: 20px;
        height: 20px;
        font-size: 12px;
    }
    .calc_steps > div:before{
        right: 30px;
    }
    .calc_steps > div:after{
        left: 30px;
    }
    .calc .jq-selectbox__select{
        height: 40px;
        padding-right: 30px;
    }
    .calc .jq-selectbox__select-text{
        line-height: 40px;
    }
    .jq-selectbox__select{
        font-size: 12px;
    }
    .calc .jq-selectbox__trigger{
        width: 30px;
    }
    .transparent_btn, .ornage_btn{
        height: 40px;
    }
    .section_title_sm{
        font-size: 27px;
        line-height: 34px;
    }
    .news_item_title{
        font-size: 22px;
    }
    .footer_contacts{
        font-size: 12px;
    }
    .lang_and_link{
        font-size: 14px;
    }
    .menu_lang{
        font-size: 18px;
    }
    .footer_lang span:first-child, .menu_lang span:first-child{
        margin-right: 15px;
    }
    .menu .transparent_btn{
        width: 270px;
    }
    .calc input{
        height: 40px;
    }
    .calc_steps > div span{
        img{
            width: 10px;
        }
    }
    .menu{
        min-height: unset;
        padding-top: 100px;
        height: 100vh;
    }
    .menu .close_btn,.menu_lang{
        top: 30px;
    }
    .services_row > div{
        width: 50%;
    }
    .services_item{
        width: 120px;
        height: 120px;
    }
    .article_title{
        font-size: 18px;
    }
    .bread_crumbs{
        display: none;
    }
    .article_page .services_row > div{
        width: 100%;
    }
    .services_page_table li{
        flex-direction: column;
        text-align: center;
    }
    .price_section_item{
        flex-direction: column;
        justify-content: center;
        &_text li{
            text-align: left;
        }
    }
    .sm_slider_wrap{
        width: 100%;
        overflow: hidden;
        margin: 20px 0 ;
      }
}
@media(max-width:500px){
    .items_row{
        grid-template-columns: repeat(1, 1fr);
    }
    .directory_page .news_item{
        transform: none !important;
    }
    .directory_page .news_item{
        padding: 40px 15px;
    }
    .items_row{
        padding-top: 40px;
    }
    .all_proj .filter_btns{
        font-size: 9px;
        height: 35px;
    }
    .filter_select .jq-selectbox__select{
        height: 35px;
    }
    .filter_select .jq-selectbox__select-text{
        line-height: 35px;
    }
}
