.exit_popup_wrap{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 20000;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.98) 7.81%, rgba(0, 0, 0, 0.95) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.exit_popup{
    width: 600px;
    height: auto;
    max-height: 80vh;
    overflow: scroll;
    &_title{
        font-weight: bold;
        font-size: 50px;
        line-height: 60px;
        color: #EFC94F;
        margin-bottom: 20px;
    }
    ul{
        padding-left: 8px;
    }
    li{
        font-family: 'Montserrat';  
        font-size: 24px;
        line-height: 46px;
        color: #FFFFFF;
        position: relative;
        padding-left: 15px;
        &:after{
            @include after;
            left: 0;
            top: 19px;
            width: 8px;
            height: 8px;
            background: #F5CB45;
            transform: rotate(45deg);
        }
    }
    &_text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
    }
    &_img{
        position: relative;
        overflow: hidden;
        margin: 20px 0;
        img{
           width: 100%;
           height: auto;
            margin: 0 auto;
        }
    }
    .ornage_btn,.transparent_btn{
        width: 100%;
        margin-top: 20px;
    }
    .transparent_btn{
        span{
            color: #fff;
        }
    }
    &_close{
        position: absolute;
        right: 50px;
        top: 50px;
        cursor: pointer;
    }
}
.consult_popup{
    .modal-header .close{
        margin: -1rem  auto -1rem -1rem ;
        img{
            width: 20px;
            height: 20px;;
        }
    }
    form{
        width: 100%;
        padding: 0 10% 50px 10%;
        p{
            font-weight: bold;
            font-size: 30px;
            line-height: 46px;
            color: #000;
            margin-bottom: 40px;
            text-transform: uppercase;
        }
        input:not([type='submit']){
            width: 100%;
            height: 50px;
            border: 1px solid #4F4F4F;
            margin-bottom: 40px;
            display: block;
            padding-left: 10px;
            
        }
    }
        border: none;
        border-radius: 0;
        .modal-header{
            border:none;
        }
}

.consult_popup_wrap{
    .modal-dialog{
        max-width: 600px !important;
    }
}