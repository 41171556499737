.map{
    position: relative;
    overflow: hidden;
    height: 300px;
    width: 100%;
    img{
        width: 100%;
        object-fit: cover;
        height: 100%;
        object-position: center;
    }
    .bread_crumbs{
        position: absolute;
        left: 0;
        top: 0;
    }
}
.contacts_item{
    margin-top: 70px;
    max-width: 400px;
    >*{
        font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    }
    .contacts_item_sm{
        font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    margin-bottom: 10px;
    }
}
.contacts_page_content{
    padding-bottom: 80px;
    >.container{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    form{
        margin-top: -100px;
        background-color: #fff;
        padding: 90px 70px 56px 70px;
        width: 600px; 
        position: relative;
        z-index: 2;
        input,textarea{
            width: 100%;
            border-bottom: 1px solid #4F4F4F;
            color: #000 !important;
            height: 45px;
            appearance: initial;
            margin-bottom: 30px;
            font-size: 12px;
            &::placeholder{
                font-family: 'Montserrat';
                font-size: 12px;

            }
        }
        textarea{
            height: auto;
        }
    }
    .ornage_btn{
        background-color: #000;
        color: #fff !important;
    }
}
.form_title{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.3em;
    color: #000;
    margin-bottom: 80px;
    text-align: center;
}
.footer_contacts_page{
    background-color: #181818;
}