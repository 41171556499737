.all_proj{
    .item_link .item_link_dsc{
        bottom: 0;
    }
    .new_proj{
        padding-bottom: 0;
    }
    .show_more{
        margin-top: 70px;
    }
    .seo_block{
        margin-top: 15px;
        padding-top: 80px;
    }
    .section_seo{
        background-color: #333333;
    }
    .filter_select{
        width: 260px;
    }
    .filter_btns{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 33%;
        font-weight: bold;
        border: 1px solid #FFFFFF;
        &:first-child{
            border-right: none;
        }
        &:last-child{
            border-left: none;
        }
        &_wrap{
            display: flex;
            width: 70%;
        }
    }
    .seo_content{
        max-height: 480px;
    }

}
.filter_select .jq-selectbox__select{
    height: 60px;
    background:none;
}
.filter_select .jq-selectbox__select-text{
    line-height: 60px;
}

.all_proj .new_proj{
    padding-top: 0;
}
.filter_btns.active{
    background-color: #EFC94F;
    color: #000;
    border: 1px solid #EFC94F;
}
.filter_btns:not(.active):hover{
color: #EFC94F;
}
.done_proj_page{
    .item_link_dsc p{
        margin-bottom: 0;
    }
}
.all_proj_fix{
    .item_link{
        &_dsc{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0 0 20px  !important;
            height: 50px;
            p{
                margin-bottom: 0;
                font-weight: normal;
                font-size: 18px;
            }
            span{
                display: flex;
                align-items: center;
                height: 50px;
                background-color: #EFC94F;
                padding: 0 20px;
                color: #000;
            }
        }
    }
}
.more_filters{
    border-top: 1px solid #333333;
    padding: 50px 0;

    li{
        margin-bottom: 20px;
        color: #828282;
        a:hover{
            color: #EFC94F;
        }
    }
}