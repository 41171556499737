@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700,800|Roboto:400,500,700&display=swap');

html {
  font-size: 16px;
}
@media(min-width:1600px){
  .container{
    max-width: 1515px;
  }
}
@mixin main_btn{
  font-family: 'Montserrat', sans-serif;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  span{ 
     font-size: 14px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #000;
  }
}
@mixin after{
  content: '';
  position: absolute;
}
body {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
  font-size: 14px;
  color: #fff;
  background-color: #000;
}
.mon{
  font-family: 'Montserrat', sans-serif;
}

a:hover {
  cursor: pointer;
}

button {
  cursor: pointer;
  outline: none !important;
}
main{
  padding-top: 120px;
}

textarea {
  resize: none;
}

button {
  font-size: unset;
}

.just_center {
  justify-content: center;
  display: flex;
}

.just_btwn {
  justify-content: space-between;
  display: flex;
}

.align_center {
  display: flex;
  align-items: center;
}

header{
  height: 120px;
  padding: 0 35px;
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: #000;
  .container-fluid{
    height: 100%;
    >div:last-child{
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.3em;
    }
  }
}
.header_logo{
  margin-left: 90px;
}
.header_lang{
  margin-right: 15vw;
  color: #828282;
span:first-child{
  margin-right: 50px;
  color: #fff;
}
}
.header_tel{
  span{
    margin-left: 20px;
  }
}
.menu_btn_wrap{
  p{
font-size: 14px;
line-height: 24px;
text-transform: uppercase;
  }
}
.ornage_btn{
  @include main_btn;
  width: 550px;
  background: #EFC94F;
  transition: all 0.3s;
  &:hover{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  }
}
.first_view{
  position: relative;
  font-family: 'Montserrat', sans-serif;
  padding-top: 85px;
  padding-bottom: 120px;
  background-image: url('../img/fbg.png');
  background-size: cover;
  .ornage_btn{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    &:hover{
      box-shadow: none;
    }
  }
  &:after{
    @include after;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.6;
  }
  &_content{
    position: relative;
    z-index: 1;
    p{
        font-weight: bold;
      font-size: 56px;
      line-height: 70px;
      text-transform: uppercase;
      max-width: 700px;
      margin-bottom: 20px;
    }
    &_bottom{
      display: flex;
      align-items: flex-end;
    }
  }
  ul{
    margin-right: 120px;
    li{
      position: relative;
      padding-left: 30px;
      text-transform: capitalize;
      font-size: 24px;
      line-height: 46px;
      &::after{
       @include after;
        width: 8px;
        height: 8px;
        background: #F5CB45;
        transform: rotate(45deg) translate(0,-50%);
        top: 50%;
        left: 0;
      }
    }
  }
}

.section_title{
  position: relative;
  text-transform: uppercase;
  height: 160px;
  display: flex;
  align-items: flex-end;
  &_bg{
    font-weight: bold;
    font-size: 144px;
    opacity: 0.1;
    line-height: 105%;
    position: absolute;
    left: -100px;
    bottom: 0;
  }
  &_sm{
    font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  &:after{
    @include after;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 130px;
    height: 2px;;
    background-color: #F5CB45;
  }
  }
}
.item_link{
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 16px;
  max-height: 370px;
  img{
    width: 100%;
  }
  &_dsc{
    position: absolute;
    bottom: -300px;
    left: 0;
    width: 100%;
    padding: 30px  10%;
    background: rgba(19, 27, 34, 0.7);
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    transition: all 0.4s;
    p{
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 20px;
    }
    div{
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      line-height: 120%;
    }
    }
}
.item_link:hover{
  .item_link_dsc{
    bottom: 0px;
  }
}

.transparent_btn{
  @include main_btn;
  border:1px solid #fff;
  width: 590px;
  span{
    margin-right: 30px;
  }
}
.new_proj{
  padding-top: 50px;
  padding-bottom: 100px;
 .section_title{
   margin-bottom: 55px;
 } 
 .transparent_btn{
   margin-top: 75px;
   span{
     color: #fff;
   }
 }
}
.services_item{
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.2); 
    width: 282px;
    height: 282px;
    margin-top: 80px;
  
  p{
    margin-top: 40px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }
}
.services{
  background-color: #EFC94F;
    position: relative;
  >*{
    position: relative;
    z-index: 2;
  }
      &::after{
        @include after;
        left: 0;
        top: 0;
        width: 100%;
        height: 360px;
        background-color: #000;
        z-index: 1;
      }
      &:before{
        @include after;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 550px;
        background-color: #fff;
        z-index: 1;
      }
  &_row{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    >div{
      width: 33%;
      display: flex;
      justify-content: center;
    }
  }
  .transparent_btn{
    margin-top: 70px;
   border: 1px solid #000;
   margin-bottom: 100px;
  }
  &_title{
    background-color: #000;
  }
}
.calc{
  background: #333333;
  width: 768px;
  padding: 75px 155px;
  position: relative;
  z-index: 2;
  &_sub_title{
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.3em;
    margin-top: 15px;
    margin-bottom: 36px;
  }
  &_title{
      font-weight: bold;
  font-size: 56px;
  line-height: 120%;
  text-transform: uppercase;
  }
}
.calc_steps{
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  margin-bottom: 70px;
  >div{
    position: relative;
    span{
      display: flex;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 20px;
    }
  &::after,&:before{
    @include after;
    top: 50%;
    height: 1px;
    width: 100px;
    background-color: #EFC94F;
  }
  &:after{
    left: 50px;
  }
  &:before{
    right: 50px;
  }
}
}
.calc_step_1{
  margin-left:calc(33% - 35px);
  span{
    background-color: #EFC94F;
    color: #000000;
  }
}
.calc_step_2{
  margin-right:calc(33% - 35px);

  span{
    background-color: transparent;
    border: 1px solid #fff;
  }
}
.calc ,.filter_select{

  .jqselect{
    width: 100%;
    margin-bottom: 45px;
  }
  .jq-selectbox__select {
    background: #333;
    border: 1px solid #fff !important;
    border-radius: 0;
    color: #fff;
    height: 50px;
    outline: none;
    &-text{
      text-shadow: none;
      height: 100%;
      line-height: 50px;
    }
  }
  .jq-selectbox__trigger{
    border: none;
    width: 50px;
  }
  .jq-selectbox__trigger-arrow{
    border-top: 5px solid #fff !important;
    top: 50%;
    transform: translate(50%,-50%);
    right: 50%;
  }
  .jq-selectbox__dropdown{
      padding: 5px 30px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
      border-radius: 0;
      border: none;
      top: 53px;
      &::after{
        @include after;
        width: 0; 
        height: 0; 
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #fff;
        top: -11px;
        right: 14px;
      }
  }
  .ornage_btn{
    box-shadow: none;
  }
  input{
    width: 100%;
    height: 50px;
    padding-left: 15px;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    font-size: 14px;
    margin-bottom: 45px;
  }
}
.jq-selectbox li{
  padding: 15px 5px;
  border-bottom: 1px solid #E0E0E0;
}
.jq-selectbox li.selected{
  background-color: transparent;
  color: #000;
}
.jq-selectbox li:hover{
  background-color: transparent;
  color: #000;
  font-weight: bold;
}
.calc_1{
  .calc_step_2::after{
    background-color: #FFFFFF;
  }
}
.calc_2{
  .calc_step_2{
    span{
      background-color: #EFC94F;
      color: #333;
      border: none;
    }
  }
}
.calc_1 , .calc_2{
  display: none;
}
.active_calc{
  display: block;
}
.calc_section{
  position: relative;
  padding: 50px 0;
  .container{
    display: flex;
    justify-content: flex-end;

  }
  &_img{
    position: absolute;
    left: 0;
    top: 0;
    max-width: 68vw;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.done_proj{
  background-color: #fff;
  color: #000;
  padding-top: 250px;
  .transparent_btn{
    border: 1px solid #191919;
    span{
      color: #000;
    }
  }
  .item_link_dsc{
    text-align: center;
    color: #fff;
  }
}

// 
.home_news{
  .news_item{
    max-width: 593px;
    margin-top: 26px;
  
  }
  .col-lg-6{  display: flex;
    flex-direction: column;}

  .col-lg-6:nth-child(2){
    margin-top: -100px;
    >div:first-child{
      background-color: #fff;
    }
  }

  .col-lg-6:nth-child(1){
        align-items: flex-end;
        >div:last-child{
          background-color: #fff;
        }
  }
}
.news_item{
  padding: 50px 10%;
  color: #000;
  background-color: #EFC94F;
  line-height: 170%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  &_category{
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    margin-left: -10px;
  }
  &_title{
    font-weight: 500;
    font-size: 45px;
    line-height: 120%;
    margin: 30px 0;
  }
  .more_btn{
    margin-top: 40px;
  }
}
.more_btn{
  border: none;
  width: auto;
  height: auto;
  justify-content: flex-start;
  span{
    margin-right: 15px;
  }
}
.home_news{
  background-color: #fff;
  .section_title{
    color: #000;
  }
  .news_content{
    position: relative;
    background-color: #333;
    background-image: url('../img/bg1.png');
    background-repeat: no-repeat;
    background-position-y: 170px;
    >.container{
      position: relative;
      z-index: 2;
    }
    &::after{
      @include after;
      top: 0;
      left: 0;
      width: 100vw;
      height: 170px;
      background-color: #fff;
      z-index: 0;

    }
  }
}
.seo_block{
  margin-top: 180px;
  margin-bottom: 120px;
}

.seo_title{
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 30px;
}
.seo_content{
  padding-right: 50px;
  max-height: 250px;
  overflow-y: scroll;
  font-size: 16px;
line-height: 24px;
}
.footer_contacts{
  background: #181818;
  display: flex;
  justify-content: space-between;

  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.3em;
  text-align: center;

color: #FFFFFF;
  &_item{
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 70px;
    &:hover{
      background-color: #131313;
    }
    >div{
      margin-bottom: 35px;
    }
  }
}
.footer_main{
  display: flex;
  justify-content: space-between;
  padding: 0 8%;
  padding-top: 105px;
  padding-bottom: 80px;
}
.footer_logo_wrap{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.directory{
  letter-spacing: 0.3em;
  font-family: 'Montserrat', sans-serif;

  >p{
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 35px;
  }
  li{
    font-size: 16px;
    line-height: 20px;
    color: #C4C4C4;
    &:not(:last-child){
      margin-bottom: 15px;
    }
  }
}
.lang_and_link{
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-size: 18px;
}
.footer_lang,.menu_lang{
  color: #828282;
  margin-bottom: 55px;
  span:first-child{
    color: #fff;
    margin-right: 30px;
  }
}

// side nav
.side_nav{
  position: absolute;
  right: 40px;
  top: 130px;
  z-index: 20;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  white-space: nowrap;
  width: 10px;
  ul{
    display: flex;
    transform: rotate(90deg);
  }
  li{
    margin-right: 25px;
    position: relative;
    transition: all 0.25s;
    &:not(:last-child):after{
      @include after;
      right: -21px;
      width: 18px;
      height: 1px;
      top: calc(50%);
      transform: translateY(-50%);
      background-color: #fff;
    }
    &.active{
      color: #EFC94F;
    }
  }
}
.sticky{
  position: fixed;
  top: 140px;
}
.side_nav li.dark{
  color: #131313;
  &.active{
    color: #EFC94F;
  }
}
.side_nav li.one_dark.active{
  color: #131313;
}
// menu 


.menu{
  width: 100vw;
  height: 100vh;
  background-color: #000;
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  display: none;
  &_content{
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    width: 100%;
  }
.close_btn{
  position: absolute;
  left: 50px;
  top: 50px;
  font-size: 14px;
line-height: 24px;
text-align: center;
text-transform: uppercase;
display: flex;
align-items: center;
span{
  letter-spacing: 0.5em;
  margin-left: 20px;
}
}

li{
  font-weight: 300;
font-size: 36px;
line-height: 44px;
color: #FFFFFF;
transform: translateY(-100vh);
transition: all 0.8s;
&:not(:last-child){
  margin-bottom: 5vh;
}
}
  .transparent_btn{
    margin-top: 140px;
    border: 1px solid #F5CB45;
    width: 440px;
    span{
      color: #F5CB45;

    }
  }
}
.menu_lang{
  font-size: 24px;
line-height: 29px;
}

.menu_right{
  >*{
    transform: translateX(100vw);
    transition: all 0.5s;
    &:first-child{
      transition-delay: 1.2s;
    }
    &:last-child{
      transition-delay: 1s;
    }
  }
}
.li_transform{
  transform: translateY(0) !important;
}
.menu li:nth-child(7){
  transition-delay: 0.45s;
}
.menu li:nth-child(6){
  transition-delay: 0.55s;
}
.menu li:nth-child(5){
  transition-delay: 0.65s;
}
.menu li:nth-child(4){
  transition-delay: 0.75s;
}
.menu li:nth-child(3){
  transition-delay: 0.85s;
}
.menu li:nth-child(2){
  transition-delay: 0.95s;
}
.menu li:nth-child(1){
  transition-delay: 1s;
}

.calc .ornage_btn{
  background-color: #4F4F4F;
}
.ornage_btn{
  transition: all 0.3s;
}
.ornage_btn:hover{
  box-shadow: 0px 4px 10px rgba(239, 201, 79, 0.75);
}
.transparent_btn:hover{
  img{
    transform: scale(1.2) translateX(10px);
  }
}
.transparent_btn img ,.menu_lang span,.footer_lang span,.header_lang span{
  transition: all 0.3s;
  cursor: pointer;  

}
.menu li a,.close_btn ,.directory li,.lang_and_link >a,.header_tel span{
  transition: color 0.2s;
}
.menu_lang span:hover,.footer_lang span:hover,.menu li:hover a,.close_btn:hover,
.header_lang span:hover ,.directory li:hover,.header_tel:hover{
  cursor: pointer;  
 color: #EFC94F;  
}
.close_btn{
  cursor:pointer;
}
.lang_and_link >a:hover{
  color:#4267B2;
}
.calc .jq-selectbox__dropdown::after{
  display: none;
}
.menu{
  overflow: hidden;
}
.seo_block {
  overflow: hidden;
}

.calc_page{
  background-color: #000;
}

.promo_page{
  background-color: #fff;
  color: #000;
  padding-bottom:100px;
  .bread_crumbs{
    margin-bottom: 30px;
  }
  .section_title_sm{
    margin-bottom: 40px;
  }
  p{
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 160%;
  }
    .promo_imgs{
      justify-content: center;
    }
}
.header_logo a img,.footer_logo_wrap a img{
  transition: all 0.3s;
  &:hover{
    transform: scale(1.1);
  }
}
.interesting_block{
  transition: all 0.3s;
&:hover{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
}
.contacts_page_content a:hover{
    color: #EFC94F;
 
}
.contacts_page_content{
  form{
    .ornage_btn{
      cursor: pointer;
      &:hover{
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);

      }
    }
  }
}
.ornage_btn{
  cursor: pointer;
}

.lang_and_link{
  >a{
    display: block
  }
  >a:last-child{
    margin-top: 30px;
    &:hover{
      color: #FE33B9;
    }
  }
}
