.sm_slide{
    .img_wrap{
        height: 120px;
        padding: 0 15px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: all 0.4s;
        }
    }
    &_content{
        &:before{
            @include after;
            width: calc(100% - 30px);
            height: 100%;
            left: 15px;
            top:0;
            background: rgba(19, 27, 34, 0.7);
            display: none;
        }
    }
}
.sm_slide.slick-current .sm_slide_content{
    &:before{
        display: block;
    }
}
.slide{
    .img_wrap{
        height:700px;
        width: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.sm_slider_wrap{
    margin:20px -15px;
}
.plan_img{
    margin-top: 20px;
}
.sm_slide img:hover{
    transform: scale(1.2);
}
.single_proj_dscr_item{
    max-width: 23%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p:first-child{
        font-family: 'Montserrat';
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.3em;
        color: #4F4F4F;
    }
    p{
        margin-top: 15px;
        color: #000;
        font-size: 18px;
        line-height: 130%;
    }
}
.single_proj_dscr{
    padding-top: 70px;
    background-color: #F2F2F2;
    padding-bottom: 80px;
}
.single_proj_text{
    font-size: 14px;
    line-height: 24px;
    color: #333;
    margin-top: 50px;
    p{
        margin-bottom: 20px;
    }
}
.more_inf{
    background-image: url('../img/moreinf.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding:100px 0;
    &::after{
        @include after;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(51, 51, 51,0.8);
        z-index: 0;
    }
    form{
        position: relative;
        z-index: 1;
        >*{
            width: 30%;
        }
    }
    input:not(.ornage_btn){
        height: 50px;
        border: 1px solid #fff;
        padding-left: 15px;
        color: #fff;
        &::placeholder{
            color: #fff;

        }
    }
    .ornage_btn{
        margin: 0;
        font-family: 'Montserrat';
        letter-spacing: 0.3em;
    }
     h3{
        margin: 0;
        color: #fff;

    }
    .section_title_sm{
        position: relative;
        z-index:1;
        margin-bottom: 90px;
    }
}
.interest_items{
    padding: 80px 15px;
    h3{
        margin-bottom: 30px;
    }
    .item_link{
        max-height: 300px;
        &_dsc{
            padding: 15px 10%;
        }
    }
}
.done_signle_proj{
    .single_proj_dscr_item{
        margin-bottom: 40px;
    }
    .single_proj_dscr_row{
        flex-wrap: wrap;
    }
}